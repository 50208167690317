.App-journey {
  margin-top: 50px;
}

.App-teacherflix-video {
  padding-left: 15px;
  padding-right: 15px;
}

iframe {
  height: 500px;
  width: 1000px;
}

@media screen and (max-width: 1035px) {
  iframe {
    width: 100%;
    height: 300px;
  }
}