* {
  font-family: 'Montserrat', sans-serif;
}

html, body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #16161e;
  margin: 0;
  padding: 0;
}
