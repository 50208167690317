.App-plan-body {
  margin-top: 100px;
}

.App-plan-section {
  background-color: #0e0e12;
  color: white;
  width: 520px;
  border-radius: 15px;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
  padding: 35px 30px;
  text-decoration: none;
  font-size: 23px;
}

.App-plan-p {
  line-height: 28px;
  font-size: 16px;
}

@media screen and (max-width: 600px) {
  .App-plan-section {
    width: 250px;
  }
}
