body {
  transition: 0.5s;
  min-height: 93vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 18px;
  color: white;
  font-weight: inherit;
}

p {
  color: white;
  font-size: 16px;
}

.App {
  transition: 0.3s;
  color: #7932b6;
  text-align: center;
  padding: 35px 0px 50px 0px;
}

@media screen and (min-width: 450px) {
  .App {
    display: table-caption;
  }
}

.App-brazil {
  height: 65px;
  position: absolute;
  text-align: center;
  font-size: 12px;;
  color: rgb(40, 40, 40);
  top: 9.5%;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
}

.App-section {
  overflow: auto;
  max-width: 40%;
  min-width: 400px;
  max-height: 250px ;
  padding: 0px 15px;
  text-align: left;
  color: white;
  background-color: #7932b6;
  font-size: 15px;
  border: 0.5px solid #7932b6;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 20px;
}

@media screen and (max-width: 1000px) {
  .App-section {
    max-width: 75%;
    min-width: inherit;
  }
}

.App-transcript {
  max-width: 80%;
  max-height: 100px;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}

.App-input {
  width: 240px;
  height: 50px;
  padding: 0px 15px;
  background-color: white;
  font-size: 16px;
  border: 0.5px solid #7932b6;
  border-radius: 5px;
}

.App-input:focus {
  outline: none;
}

@media screen and (max-width: 1000px) {
  .App-input {
    width: 210px;
  }
}

.App-button {
  -webkit-tap-highlight-color: transparent;
  width: 240px;
  padding: 15px;
  color: white;
  background-color: transparent;
  font-size: 16px;
  border: 0.5px solid #7932b6;
  border-radius: 5px;
  cursor: pointer;
}

.App-button-hover {
  -webkit-tap-highlight-color: transparent;
  width: 240px;
  padding: 15px;
  font-size: 16px;
  border: 0.5px solid #7932b6;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  background-color: #7932b6;
  transition: 500ms;
}

.App-button:hover {
  color: white;
  background-color: #7932b6;
  transition: 500ms;
}

@media screen and (max-width: 1000px) {
  .App-button:hover {
    background-color: transparent;
    color: #7932b6;

  }

  .App-button:active {
    background-color: #7932b6;
    color: white;
  }
}

.App-button-golden {
  -webkit-tap-highlight-color: transparent;
  width: 240px;
  padding: 15px;
  color: #ff9301;
  background-color: transparent;
  font-size: 16px;
  border: 0.5px solid #ff9301;
  border-radius: 5px;
  cursor: pointer;
}

.App-button-golden:hover {
  color: white;
  background-color: #ff9301;
  transition: 500ms;
}

@media screen and (max-width: 1000px) {
  .App-button-golden:hover {
    background-color: transparent;
    color: #ff9301;

  }

  .App-button-golden:active {
    background-color: #ff9301;
    color: white;
  }
}

.App-button-no-border {
  width: 240px;
  padding: 15px;
  color: white;
  background-color: transparent;
  font-size: 16px;
  border: 0px solid transparent;
  cursor: pointer;
}

.App-button-no-border:hover {
  color: white;
  transition: 500ms;
}

.App-button-no-border-golden {
  width: 240px;
  padding: 15px;
  color: #ff9301;
  background-color: transparent;
  font-size: 16px;
  border: 0px solid transparent;
  cursor: pointer;
}

.App-button-no-border-golden:hover {
  color: white;
  transition: 500ms;
}

.App-button-white {
  width: 240px;
  padding: 15px;
  color: white;
  background-color: #ff9301;
  font-size: 16px;
  border: 0.5px solid #ff9301;
  border-radius: 5px;
  cursor: pointer;
}

.App-button-cancel {
  width: 240px;
  padding: 15px;
  color: red;
  border: 0px;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
}

.App-line {
  width: 1px;
  height: 35px;
  background-color: #7932b6;
  margin: auto;
}

.App-side-bar {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #15161e !important;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.App-side-bar i {
  color: white;
  font-size: 25px;
  cursor: pointer;
  margin-left: 15px;
  margin-right: 15px;
}

.App-contact {
  position: absolute;
  text-align: center;
  font-size: 12px;;
  color: white;
  bottom: 0%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.loader {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 62px;
  height: 62px;
  background: linear-gradient(to right, #7932b6 20%, #0000 21%);
  background-repeat: repeat-x;
  background-size: 36px 8px;
  background-position: 9px bottom;
  animation: moveX 0.5s linear infinite;
}
.loader::before  {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  border: 3.5px solid #7932b6;
  background-color: transparent;
  left: 50%;
  top: 55%;
  transform: translate(-50% , -50% );
  animation: rotate 0.5s linear infinite;
}

@keyframes moveX {
0%, 25%{    background-position: 10px bottom }
75% , 100% {background-position: -30px bottom;}
}
@keyframes rotate {
0%, 25% { transform:translate(-50% , -50% ) rotate(0deg)}
75%,  100% { transform:translate(-55% , -55% ) rotate(90deg)}
}


@keyframes round {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}